var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px","scrollable":""},model:{value:(_vm.openForm),callback:function ($$v) {_vm.openForm=$$v},expression:"openForm"}},[_c('v-card',[_c('v-card-title',[(_vm.formUserType == 'Create')?_c('span',[_vm._v("Crear usuario")]):_c('span',[_vm._v("Actualizar usuario")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"formUser",attrs:{"id":"formUser"},model:{value:(_vm.formUserValidator),callback:function ($$v) {_vm.formUserValidator=$$v},expression:"formUserValidator"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Nombres","rules":[_vm.rules.required, _vm.rules.minLength, _vm.rules.maxLength]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Apellidos","rules":[_vm.rules.required, _vm.rules.minLength, _vm.rules.maxLength]},model:{value:(_vm.lastname),callback:function ($$v) {_vm.lastname=$$v},expression:"lastname"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Correo","rules":[
                      _vm.rules.required,
                      _vm.rules.minLength,
                      _vm.rules.maxLength,
                      _vm.rules.email,
                    ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Usuario","rules":[_vm.rules.required, _vm.rules.minLength, _vm.rules.maxLength]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0","sm":"6"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Url del reporte","rules":[]},model:{value:(_vm.report_url),callback:function ($$v) {_vm.report_url=$$v},expression:"report_url"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-autocomplete',{staticClass:"my-0 py-0",attrs:{"items":_vm.sapProjectIdList,"item-value":"sap_project_id","item-text":"sap_project_id","chips":"","small-chips":"","label":"Proyectos a asignar","multiple":""},model:{value:(_vm.sapProjectIdSelected),callback:function ($$v) {_vm.sapProjectIdSelected=$$v},expression:"sapProjectIdSelected"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0","sm":"6"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Celular","maxlength":"9","rules":[
                      _vm.rules.required,
                      _vm.rules.phoneLength,
                      _vm.rules.is_number,
                    ],"counter":9},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1),_c('v-col',{attrs:{"cols":"12 my-0 py-0","sm":"6"}},[_c('v-select',{staticClass:"my-0 py-0",attrs:{"items":_vm.roleList,"item-value":"id","item-text":"name","label":"Rol","rules":[_vm.rules.required]},model:{value:(_vm.role_id),callback:function ($$v) {_vm.role_id=$$v},expression:"role_id"}})],1),(_vm.formUserType == 'Create')?_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Contraseña","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password',"rules":[
                      _vm.rules.required,
                      _vm.rules.minLength8,
                      _vm.rules.maxLength,
                      _vm.rules.hasUpperCase,
                      _vm.rules.hasNumber,
                      _vm.rules.hasSpecialChar,
                    ]},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1):_c('v-col',{attrs:{"cols":"12 my-0 py-0"}},[_c('v-switch',{attrs:{"label":_vm.isActivePassUpdate
                        ? 'Editar contraseña'
                        : 'No editar contraseña'},model:{value:(_vm.isActivePassUpdate),callback:function ($$v) {_vm.isActivePassUpdate=$$v},expression:"isActivePassUpdate"}}),(_vm.isActivePassUpdate)?_c('v-text-field',{staticClass:"my-0 py-0",attrs:{"label":"Contraseña","rules":[_vm.rules.required, _vm.rules.minLength, _vm.rules.maxLength],"append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password'},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}):_vm._e()],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.closeFormUser()}}},[_vm._v(" Cerrar ")]),(_vm.formUserType == 'Create')?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loader},on:{"click":function($event){return _vm.createUser()}}},[_vm._v(" Crear ")]):_c('v-btn',{attrs:{"color":"primary","loading":_vm.loader},on:{"click":function($event){return _vm.updateUser()}}},[_vm._v(" Actualizar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }