var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"main-container",attrs:{"align":"center"}},[_c('v-col',{staticClass:"hidden-sm-and-down login-form",attrs:{"md":"7"}}),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"10"}},[_c('v-container',{staticClass:"text-xs-center"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('img',{staticClass:"logo",attrs:{"src":"/favicon.png","alt":"logo"}})]),_c('v-form',{staticClass:"mx-6 px-6"},[_c('v-text-field',{attrs:{"label":"Usuario","rules":[_vm.rules.required, _vm.rules.maxLength, _vm.rules.email]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('v-text-field',{attrs:{"label":"Contraseña","append-icon":_vm.showPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPass ? 'text' : 'password',"rules":[
              _vm.rules.required,
              _vm.rules.minLength,
              _vm.rules.maxLength,
              _vm.rules.hasUpperCase,
              _vm.rules.hasNumber,
              _vm.rules.hasSpecialChar,
            ]},on:{"click:append":function($event){_vm.showPass = !_vm.showPass}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-btn',{staticClass:"my-8",attrs:{"color":"primary","block":"","loading":_vm.loadingLoginButton},on:{"click":function($event){return _vm.login(_vm.username, _vm.password)}}},[_vm._v("Iniciar Sesión")]),_c('v-row',{attrs:{"justify":"start"}},[_c('a',{staticClass:"gray-color subtitle-2",on:{"click":function($event){return _vm.goToPasswordDependsOfPath()}}},[_vm._v("¿Olvidaste tu contraseña?")])])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }