var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{attrs:{"fill-height":"","fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',[_c('v-form',{ref:"updatePasswordForm",staticClass:"mx-6",attrs:{"id":"updatePasswordForm"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"my-8",attrs:{"cols":"12","sm":"10","md":"7","lg":"6"}},[_c('p',{staticClass:"title font-weight-bold mb-8"},[_vm._v("Actualiza tu contraseña")]),_c('p',{staticClass:"text-left body-2 mb-8"},[_vm._v(" La contraseña ha expirado, ingresa una nueva para acceder a tu cuenta. Recuerda que esta debe tener una combinación de al menos ocho caracteres que contengan un número, una mayúscula y un caracter especial. ")]),_c('v-text-field',{attrs:{"label":"Escribe una nueva contraseña","name":"newPassword","append-icon":_vm.showNewPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showNewPass ? 'text' : 'password',"rules":[
                  _vm.rules.required,
                  _vm.rules.minLength,
                  _vm.rules.maxLength,
                  _vm.rules.hasUpperCase,
                  _vm.rules.hasNumber,
                  _vm.rules.hasSpecialChar,
                ]},on:{"click:append":function($event){_vm.showNewPass = !_vm.showNewPass}},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('v-text-field',{attrs:{"label":"Repite tu nueva contraseña","append-icon":_vm.showConfirmPass ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmPass ? 'text' : 'password',"rules":[
                  _vm.rules.required,
                  _vm.rules.minLength,
                  _vm.rules.maxLength,
                  _vm.rules.hasUpperCase,
                  _vm.rules.hasNumber,
                  _vm.rules.hasSpecialChar,
                  _vm.passwordConfirmationRule,
                ]},on:{"click:append":function($event){_vm.showConfirmPass = !_vm.showConfirmPass}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"my-8",attrs:{"color":"primary","block":"","loading":_vm.loader},on:{"click":function($event){return _vm.updatePassword(_vm.newPassword)}}},[_vm._v(" Cambiar contraseña ")])],1)],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }